import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CreateMerchantForm from "../CreateMerchantForm";
import MenuForm from "../MenuForm";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Fab,
  Grid,
  IconButton,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useAuth } from "../providers/AuthProvider";
import {
  Merchant,
  ItemConfigGroup,
  MenuItem,
  MenuItemCategory,
  MerchantBasic,
  Subscription,
} from "../models/RealmDataModels";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import StorefrontIcon from "@material-ui/icons/Storefront";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { MerchantsList } from "../MerchantsList";
import RealmUserForm from "../RealmUserForm";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { BSON } from "realm-web";
import LoadingButton from "../LoadingButton";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "../utils/useQuery";
import LinearProgress from "@material-ui/core/LinearProgress";
import MerchantManagementTab from "../MerchantManagement/MerchantManagementTab";
import HomeIcon from "@material-ui/icons/Home";

export interface AdminPanelPageProps {}
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    borderBottomWidth: 2,
    borderBottom: "10px solid " + theme.palette.primary.main,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    alignSelf: "center",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    maxHeight: "100%",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

const AdminPanelPage: React.FC<AdminPanelPageProps> = () => {
  const classes = useStyles();
  const auth = useAuth();
  const [allMerchants, setAllMerchants] = useState<MerchantBasic[]>([]);
  const [selectedMerchant, setSelectedMerchant] = useState<Merchant>();
  const [tabIndex, setTabIndex] = React.useState("merchant");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [confirmMoveModalOpen, setConfirmMoveModalOpen] = React.useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] =
    React.useState(false);
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);

  const { id: merchantSlug } = useParams<{ id: string }>();
  const query = useQuery();

  const tab = query.get("tab");

  useEffect(() => {
    if (auth.getUser()) {
      loadMerchants();
    }
  }, [auth.getUser()]);
  useEffect(() => {
    if (merchantSlug) {
      loadMerchant(merchantSlug);
      setTabIndex("merchant");
    } else {
      setSelectedMerchant(undefined);
    }
  }, [merchantSlug]);

  useEffect(() => {
    if (tab) {
      setTabIndex(tab);
    } else {
      setTabIndex("merchant");
    }
  }, [tab]);

  console.log("merchant: ", merchantSlug);

  const loadMerchant = async (merchantSlug: string) => {
    const user = auth.getUser();
    if (!user) return;

    setLoading(true);

    const mongodb = user.mongoClient("mongodb-atlas");
    const merchants = mongodb.db("jymba").collection<Merchant>("Merchant");
    const dbMerchant = await merchants.findOne({ slug: merchantSlug });

    if (dbMerchant) {
      setSelectedMerchant(dbMerchant);
      console.log("loading mercchant...");
    } else {
      console.log("undefined mercchant...");

      setSelectedMerchant(undefined);
    }
    setLoading(false);
  };

  const loadMerchants = async () => {
    const user = auth.getUser();
    if (!user) {
      console.log("No user");
      return;
    }

    const mongodb = user.mongoClient("mongodb-atlas");
    const merchants = mongodb.db("jymba").collection<MerchantBasic>("Merchant");
    const dbMerchants = await merchants.find(
      {},
      {
        projection: { _id: 1, name: 1, slug: 1, _status: 1, merchantCode: 1 },
        sort: { name: 1 },
      }
    );
    setAllMerchants(dbMerchants.sort((a, b) => a.name.localeCompare(b.name)));
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push({
      pathname: "/merchant/" + merchantSlug,
      search: "?tab=" + newValue,
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMoveDataClick = () => {
    setConfirmMoveModalOpen(true);
  };

  const deleteMerchant = () => {
    // TODO
  };

  const moveData = async () => {
    const user = auth.getUser();
    const otherUser = auth.getOtherUser();
    if (!selectedMerchant || !user || !otherUser) return;

    setLoading(true);

    const mongodb = user.mongoClient("mongodb-atlas");
    const merchantCol = mongodb.db("jymba").collection<Merchant>("Merchant");
    const categoryCol = mongodb
      .db("jymba")
      .collection<MenuItemCategory>("MenuItemCategory");
    const itemConfigGroupCol = mongodb
      .db("jymba")
      .collection<ItemConfigGroup>("ItemConfigGroup");
    const subscriptionCol = mongodb
      .db("jymba")
      .collection<Subscription>("Subscription");

    const merchantId = selectedMerchant._id;
    const moveMerchant: Merchant = {
      ...selectedMerchant,
      numReviews: new BSON.Double(0),
      rating: new BSON.Double(4),
      deliveryCost:
        typeof selectedMerchant.deliveryCost === "number"
          ? new BSON.Double(selectedMerchant.deliveryCost as number)
          : selectedMerchant.deliveryCost,
      // address: {
      //   ...selectedMerchant.address,
      //   location: {
      //     ...selectedMerchant.address.location,
      //     coordinates:
      //     [
      //         new BSON.Double(0),
      //         new BSON.Double(0),]
      //   }
      // },
      _status: "deactivated",
    };
    let moveCategories = await categoryCol.find({ merchantId: merchantId });
    moveCategories = moveCategories.map((cat) => ({
      ...cat,
      items: cat.items.map((mI) => ({
        ...mI,
        price:
          typeof mI.price === "number"
            ? new BSON.Double(mI.price as number)
            : mI.price,
      })),
    }));

    let moveItemConfigGroups = await itemConfigGroupCol.find({
      merchantId: merchantId,
    });
    moveItemConfigGroups = moveItemConfigGroups.map((cG) => ({
      ...cG,
      options: cG.options.map((opt) => ({
        ...opt,
        price:
          typeof opt.price === "number"
            ? new BSON.Double(opt.price as number)
            : opt.price,
      })),
    }));

    let moveSubscription = await subscriptionCol.findOne({
      merchantId: merchantId,
    });

    // Get the collections of the other instance
    const otherMongodb = otherUser.mongoClient("mongodb-atlas");
    const otherMerchantCol = otherMongodb
      .db("jymba")
      .collection<Merchant>("Merchant");
    const otherCategoryCol = otherMongodb
      .db("jymba")
      .collection<MenuItemCategory>("MenuItemCategory");
    const otherItemConfigGroupCol = otherMongodb
      .db("jymba")
      .collection<ItemConfigGroup>("ItemConfigGroup");
    const otherSubscriptionCol = otherMongodb
      .db("jymba")
      .collection<Subscription>("Subscription");

    // insert the documents into the other database

    // upsert merchant
    await otherMerchantCol.updateOne({ _id: moveMerchant._id }, moveMerchant, {
      upsert: true,
    });

    // upsert Subscription
    if (moveSubscription) {
      await otherSubscriptionCol.updateOne(
        { _id: moveSubscription._id },
        moveSubscription,
        { upsert: true }
      );
    }

    // upsert MenuItems
    await Promise.all(
      moveCategories.map(async (moveCategory) => {
        otherCategoryCol.updateOne({ _id: moveCategory._id }, moveCategory, {
          upsert: true,
        });
      })
    );

    // upsert ItemConfigGroup
    await Promise.all(
      moveItemConfigGroups.map(async (moveItemConfigGroup) => {
        otherItemConfigGroupCol.updateOne(
          { _id: moveItemConfigGroup._id },
          moveItemConfigGroup,
          { upsert: true }
        );
      })
    );

    // otherItemConfigGroupCol.insertMany(moveItemConfigGroupCol);

    setLoading(false);
    setConfirmMoveModalOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="default"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, drawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" color="textPrimary" noWrap>
            Jymba
            <Typography variant="body1" color="secondary" noWrap>
              {process.env.REACT_APP_ENVIRONMENT}
            </Typography>
          </Typography>
          <Box ml="auto" />
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => {
              history.push("/choose");
            }}
          >
            <HomeIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="button">
            Merchant List ({allMerchants.length})
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <div>
          <MerchantsList
            onUpdated={() => loadMerchants()}
            merchants={allMerchants}
            selectedMerchantSlug={selectedMerchant?.slug}
          />
        </div>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <Container maxWidth="lg">
          <Box display="flex" flex={1} pb={1}>
            <Fab
              style={{ marginLeft: "auto" }}
              color="secondary"
              variant="extended"
              onClick={handleMoveDataClick}
              disabled={!selectedMerchant}
            >
              <DoubleArrowIcon className={classes.extendedIcon} />
              Move Merchant to {process.env.REACT_APP_OTHER_ENVIRONMENT}
            </Fab>
          </Box>
          <Paper elevation={3} className={classes.paper}>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab
                value="merchant"
                icon={<StorefrontIcon />}
                label={selectedMerchant ? "Edit Merchant" : "Add Merchant"}
              />
              <Tab
                value="menu"
                icon={<RestaurantMenuIcon />}
                label="Edit Menu"
                disabled={selectedMerchant === undefined}
              />
              <Tab
                value="user"
                icon={<PersonAddIcon />}
                label="Edit Staff Members"
                disabled={selectedMerchant === undefined}
              />
              <Tab
                value="manage"
                icon={<SettingsIcon />}
                label="Manage Merchant"
                disabled={selectedMerchant === undefined}
              />
            </Tabs>
            {loading ? (
              <Box m={2} p={2}>
                <LinearProgress />
              </Box>
            ) : (
              <>
                {tabIndex === "merchant" && (
                  <CreateMerchantForm
                    merchantId={selectedMerchant?._id}
                    onMerchantCreated={loadMerchants}
                  />
                )}
                {selectedMerchant && tabIndex === "menu" && (
                  <MenuForm merchant={selectedMerchant} />
                )}
                {selectedMerchant && tabIndex === "user" && (
                  <RealmUserForm merchant={selectedMerchant} />
                )}
                {selectedMerchant && tabIndex === "manage" && (
                  <MerchantManagementTab merchant={selectedMerchant} />
                )}
              </>
            )}
          </Paper>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            disabled
            onClick={() => setConfirmMoveModalOpen(true)}
          >
            Delete
          </Button>
        </Container>

        <Dialog
          open={confirmMoveModalOpen}
          onClose={() => setConfirmMoveModalOpen(false)}
        >
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to move{" "}
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {selectedMerchant?.name}
              </span>{" "}
              to the {process.env.REACT_APP_OTHER_ENVIRONMENT} instance? ({selectedMerchant?._id.toHexString()})
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setConfirmMoveModalOpen(false)}
              color="primary"
            >
              No
            </Button>
            <LoadingButton
              isLoading={loading}
              variant="outlined"
              onClick={moveData}
              color="secondary"
              autoFocus
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmDeleteModalOpen}
          onClose={() => setConfirmDeleteModalOpen(false)}
        >
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to delete{" "}
              <span style={{ color: "black", fontWeight: "bolder" }}>
                {selectedMerchant?.name}
              </span>
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => setConfirmDeleteModalOpen(false)}
              color="primary"
            >
              No
            </Button>
            <LoadingButton
              isLoading={loading}
              variant="outlined"
              onClick={deleteMerchant}
              color="secondary"
              autoFocus
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </main>
    </div>
  );
};

export default AdminPanelPage;
